<template>
  <v-layout row wrap id="transaction_area">
    <v-flex sm12 md3 lg3 class="vertLine" v-if="orderType !== 1">
      <p class="body-2 font-weight-bold text-red">Danh sách dingdanhao</p>
      <v-list>
        <v-list-item-group v-model="selectedOrderCode" color="primary" mandatory>
          <v-list-item v-for="(item,index) in orderCodes" :key="item.ID">
            <v-list-item-content>
              <v-layout row wrap>
                <v-flex lg8 sm8>
                  <v-text-field v-model="item.OrderCodeCN" readonly class="no-boder"></v-text-field>
                </v-flex>
                <v-flex lg4 sm4>
                  <h-currency-input v-if="adminRole" :decimal="2" v-model="item.RealAmount" label="Phí mua thật" @press_enter="editOrderCode(item)" @on_blur="editOrderCode(item)"></h-currency-input>
                  <h-currency-input v-else :disabled="!(orderEditRole & 8) || disabled || !orderCodeEditable || locked" v-model="item.RealAmount" :decimal="2" label="Phí mua thật" @press_enter="editOrderCode(item)" @on_blur="editOrderCode(item)"></h-currency-input>
                </v-flex>
              </v-layout>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="removeOrderCode(index)" v-if="(orderEditRole & 8) && !disabled && orderCodeEditable && !locked && !paymentRole && !storeVNRole">
                <v-icon>fa-trash-alt</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-layout pl-5 pr-7 row wrap align-center v-if="(orderEditRole & 8) && !disabled && !locked && !paymentRole">
        <v-text-field
          label="Dingdanhao"
          v-model="newOrderCode.OrderCodeCN"
          class="input-group--focused mx-1 px-0 pt-0"
          @keyup.enter="addOrderCode()"
          :disabled="!(orderEditRole & 8) || disabled || !orderCodeEditable || locked"
        ></v-text-field>
        <v-btn icon @click="addOrderCode()" :disabled="!(orderEditRole & 8) || disabled || locked ||  !orderCodeEditable || newOrderCode.OrderCodeCN == ''">
          <v-icon color="#dc3545">far fa-plus-square</v-icon>
        </v-btn>
      </v-layout>
    </v-flex>
    <v-flex v-bind:class="{ 'sm12 md9 lg9': orderType !== 1, 'sm12 md12 lg12': orderType === 1}">
      <p class="body-2 font-weight-bold text-red">Danh sách mã bill</p>
      <v-layout pl-2 row wrap align-center v-for="(item,index) in selectedTransCodes" :key="index">
        <v-text-field
          label="Mã bill"
          v-model="item.TransactionCode"
          class="input-group--focused mx-1 px-0"
          @change="editTransCode(item)"
          readonly
        ></v-text-field>
        <h-currency-input
          label="Cân nặng"
          :decimal="1" 
          v-model="item.Weight"
          class="trans-weight input-group--focused mx-1 px-0"
          readonly
        ></h-currency-input>
        <h-currency-input
          label="Dài (cm)"
          :decimal="1"
          v-model="item.Length"
          class="trans-weight input-group--focused mx-1 px-0"
          readonly
        ></h-currency-input>
        <h-currency-input
          label="Rộng (cm)"
          :decimal="1"
          v-model="item.Width"
          class="trans-weight input-group--focused mx-1 px-0"
          readonly
        ></h-currency-input>
        <h-currency-input
          label="Cao (cm)"
          :decimal="1"
          v-model="item.Height"
          class="trans-weight input-group--focused mx-1 px-0"
          readonly
        ></h-currency-input>
        <v-text-field
          label="Cân nặng quy đổi"
          type="number"
          step=".1"
          v-model="item.WeightByVolume"
          class="trans-weight input-group--focused mx-1 px-0"
          readonly
        ></v-text-field>
        <v-select
          class="trans-status"
          label="Trạng thái"
          :items="transStatus"
          v-model="item.Status"
          item-disabled="disabled"
          item-value="value"
          item-text="name"
          @change="editTransCode(item)"
          :disabled="true"
        ></v-select>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="printTransaction(item.Weight, item.WeightByVolume, item.Length, item.Width, item.Height, item.TransactionCode, customerName, orderId, item.NumberOfCode)">
              <v-icon color="red darken-2">fa-print</v-icon>
            </v-btn>
          </template>
          <span>In tem</span>
        </v-tooltip>
        <v-btn icon @click="removeTransCode(index)" :disabled="((!(orderEditRole & 8) || disabled || !tranCodeEditable || checkPackageStatus(item.Status)) && !adminRole) || locked || paymentRole || storeVNRole">
          <v-icon>fa-trash-alt</v-icon>
        </v-btn>
        <v-flex lg12 md12 sm12 xs12 pt-0 mt-0 v-if="orderType === 1">
          <v-layout row wrap>
            <v-flex lg4 sm4>
              <v-text-field label="Tên hàng hoá" v-model="item.ProductName" class="input-group--focused"  :disabled="((!(orderEditRole & 8) || disabled || checkPackageStatus(item.Status) || !weightEditable) && !adminRole) || locked" @change="editTransCode(item)"></v-text-field>
            </v-flex>
            <v-flex lg3 sm3>
              <h-currency-input label="Giá trị (¥)" :decimal="1" v-model="item.ProductAmount" class="input-group--focused" :disabled="((!(orderEditRole & 8) || disabled || checkPackageStatus(item.Status) || !weightEditable) && !adminRole) || locked" @press_enter="editTransCode(item)" @on_blur="editTransCode(item)"></h-currency-input>
            </v-flex>
            <v-flex lg3 sm3>
              <h-currency-input label="Số kiện" :decimal="0" v-model="item.NumberOfPack" class="input-group--focused" :disabled="((!(orderEditRole & 8) || disabled || checkPackageStatus(item.Status) || !weightEditable) && !adminRole) || locked" @press_enter="editTransCode(item)" @on_blur="editTransCode(item)"></h-currency-input>
            </v-flex>
            <v-flex lg2 sm2>
              <h-currency-input label="Số sản phẩm" :decimal="0" v-model="item.ProductNumber" class="input-group--focused" :disabled="((!(orderEditRole & 8) || disabled || checkPackageStatus(item.Status) || !weightEditable) && !adminRole) || locked" @press_enter="editTransCode(item)" @on_blur="editTransCode(item)"></h-currency-input>
            </v-flex>
          </v-layout>
        </v-flex>        
        <v-flex lg12 md12 sm12 xs12 pt-0 mt-0>
          <v-textarea v-model="item.OrderNote" background-color="#fefefe" dense rows="3" outlined label="Ghi chú" @change="editTransCode(item)"></v-textarea>
        </v-flex>
      </v-layout>
      <v-layout pl-2 row wrap align-center v-if="(orderEditRole & 8) && !disabled && !locked && (orderType == 1 || selectedOrderCode != -1) && !(paymentRole && orderType != 1)">
        <v-text-field
          label="Mã bill"
          v-model="newOrderTrans.TransactionCode"
          class="input-group--focused mx-1 px-0"
          :disabled="!(orderEditRole & 8) || disabled || locked || !tranCodeEditable"
          @keyup.enter="addTransCode()"
        ></v-text-field>
        <!-- <v-text-field
          label="Cân nặng"
          v-model="newOrderTrans.Weight"
          class="trans-weight input-group--focused mx-1 px-0"
          :disabled="!(orderEditRole & 8) || disabled || locked || !tranCodeEditable || !weightEditable"
        ></v-text-field>
        <v-select
          class="trans-status mr-3"
          label="Trạng thái"
          :items="transStatus"
          v-model="newOrderTrans.Status"
          item-disabled="disabled"
          item-value="value"
          item-text="name"
          :disabled="true"
        ></v-select> -->
        <v-btn icon @click="addTransCode()" class="ml-5" :disabled="!(orderEditRole & 8) || disabled || locked || !tranCodeEditable || newOrderTrans.TransactionCode == ''">
          <v-icon color="#dc3545">far fa-plus-square</v-icon>
        </v-btn>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import configs from "@/configs";
import _ from "lodash";
import { mapState } from "vuex";
import { display_date } from "@/commons/formater";
import { print_transaction } from "@/commons/hpod";
import moment from "moment";

export default {
  name: "order-transaction",
  filters: {
    transLink: function(value, siteName, orderId, tmallid) {
      switch (siteName.toString()) {
        case "TAOBAO":
          // return `https://tradearchive.taobao.com/trade/detail/trade_item_detail.htm?bizOrderId=${value}&donhangchitietid=${orderId}`;
        case "TMALL":
          return `https://detail.i56.taobao.com/trace/trace_detail.htm?tId=${value}&userId=${tmallid}`;
        case "1688":
          return `https://trade.1688.com/order/new_step_order_detail.htm?orderId=${value}&donhangchitietid=${orderId}#logisticsTabTitle`;
        default:
          return `https://tradearchive.taobao.com/trade/detail/trade_item_detail.htm?bizOrderId=${value}&donhangchitietid=${orderId}`;
      }
    }
  },
  props: {
    orderStatus: {
      type: Number,
      default: null
    },
    orderType: {
      type: Number,
      default: 0
    },
    siteName: {
      type: String,
      default: "TAOBAO"
    },
    orderId: {
      type: Number,
      default: 0
    },
    customerName: {
      type: String,
      default: ""
    },
    customerID: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      transStatus: configs.PACKAGE_STATUS,
      showModalColumns: false,
      selectedOrderCode: -1,
      selectedTransCodes: [],
      newOrderCode: {
        OrderID: this.orderId,
        OrderCodeCN: "",
        RealAmount: 0,
        CreatedBy: this.$store.state.authentication.user.username
      },
      newOrderTrans: {
        OrderID: this.orderId,
        Username: this.customerName,
        UID: this.customerID,
        PackageType: this.orderType,
        TransactionView: 'order_detail',
        TransactionCode: "",
        Weight: 0.0,
        WeightByVolume: 0.0,
        Status: configs.PACKAGE_STATUS_ENUM.DANG_DI_CHUYEN,
        CreatedBy: this.$store.state.authentication.user.username
      }
    };
  },
  watch: {
    orderCodes() {
        this.selectedOrderCode = (this.selectedOrderCode == -1 || this.selectedOrderCode >= this.orderCodes.length) ? this.orderCodes.length - 1 : this.selectedOrderCode;
    },
    transCodes() {
        this.reloadSelectedTransCodes();
    },
    selectedOrderCode() {
        this.reloadSelectedTransCodes();
    }
  },
  methods: {
    reloadSelectedTransCodes() {
        if(this.orderType === 1) {
            this.selectedTransCodes = this.transCodes;
        }
        else if(this.selectedOrderCode > -1 && this.orderCodes.length > 0){
            this.selectedTransCodes = _.filter(this.transCodes, {'OrderCodeID': this.orderCodes[this.selectedOrderCode].ID});
        }
        else {
            this.selectedTransCodes = [];
        }
    },
    removeOrderCode(index) {
      const item = this.orderCodes[index];
      this.$store.dispatch("orderTransaction/deleteOrderTransaction", {
        type: "order_code",
        id: item.ID,
        orderId: item.OrderID,
        orderCode: item.OrderCodeCN,
        idx: index,
        param: this.ordercode_param
      });
    },
    removeTransCode(index) {
      const item = this.selectedTransCodes[index];
      this.$store.dispatch("orderTransaction/setDetail", {
        data: {
          'RequestAction': 'DEL',
          'TransactionView': 'order_detail',
          PackageType: this.orderType,
        },
        'id': item.ID,
        param: this.trancode_param,
        order_id: item.OrderID
      });
    },
    addOrderCode() {
      if(this.newOrderCode.OrderCodeCN == ''){
        return;
      }
      this.$store.dispatch(
        "ordercn/setDetail",
        {
          data: this.newOrderCode,
          id: this.newOrderCode.ID,
          param: this.ordercode_param
        }
      );
      this.newOrderCode = {
        OrderID: this.orderId,
        OrderCodeCN: "",
        RealAmount: 0,
        CreatedBy: this.$store.state.authentication.user.username
      };
    },
    addTransCode() {
      if(this.newOrderTrans.TransactionCode == ''){
        return;
      }
      this.newOrderTrans.Username = this.customerName;
      this.newOrderTrans.UID = this.customerID;
      if(this.orderType !== 1) {
          this.newOrderTrans.OrderCodeID = this.orderCodes[this.selectedOrderCode].ID;
      }
      
      this.newOrderTrans.Status = configs.PACKAGE_STATUS_ENUM.DANG_DI_CHUYEN;
      this.$store.dispatch(
        "orderTransaction/setDetail",
        {
          data: {
            ...this.newOrderTrans,
            TransactionView: 'order_detail'
          },
          id: this.newOrderTrans.ID,
          param: this.trancode_param
        }
      );
      this.newOrderTrans = {
        OrderID: this.orderId,
        Username: this.customerName,
        UID: this.customerID,
        TransactionCode: "",
        TransactionView: 'order_detail',
        PackageType: this.orderType,
        OrderNote: '',
        Weight: 0.0,
        WeightByVolume: 0.0,
        Status: configs.PACKAGE_STATUS_ENUM.DANG_DI_CHUYEN,
        CreatedBy: this.$store.state.authentication.user.username
      };
    },
    editOrderCode(item) {
      var data = {
        ...item,
        ModifiedBy: this.$store.state.authentication.user.username
      };
      delete data.CreatedDate;
      delete data.CreatedBy;
      this.$store.dispatch("ordercn/setDetail", {'data': data, 'id': item.ID, 'param': this.ordercode_param});
    },
    editTransCode: _.debounce(
      function(item) {
        var data = {
          ID: item.ID,
          OrderID: this.orderId,
          Status: item.Status,
          Weight: item.Weight,
          Width: item.Width,
          Height: item.Height,
          Length: item.Length,
          TransactionCode: item.TransactionCode,
          OrderNote: item.OrderNote,
          IsGuarantee: item.IsGuarantee,
          GuaranteeType: item.GuaranteeType,
          ProductName: item.ProductName,
          ProductAmount: item.ProductAmount,
          ProductNumber: item.ProductNumber,
          NumberOfPack: item.NumberOfPack,
          PackageType: item.PackageType,
          TransactionView: 'order_detail',
          ModifiedBy: this.$store.state.authentication.user.username
        };
        let guarantee = _.find(this.guarantees, {ID: item.GuaranteeType});
        data.GuaranteeRate = guarantee ? guarantee.PercentFee : 0;
        delete data.CreatedDate;
        delete data.CreatedBy;
        this.$store.dispatch("orderTransaction/setDetail", {'data': data, 'id': item.ID, 'param': this.trancode_param});
      },
      500,
      {
        leading: false,
        trailing: true
      }
    ),
    fetchData: _.debounce(
      function() {
        this.$store.dispatch("orderTransaction/getList", this.trancode_param);
        this.$store.dispatch("ordercn/getList", this.ordercode_param);
      },
      1000,
      {
        leading: true,
        trailing: false
      }
    ),
    printTransaction(weight, weight_v2, length, width, height, transactionCode, customerName, orderId, numberOfCode) {
      print_transaction(weight, weight_v2, length, width, height, transactionCode, customerName, orderId, numberOfCode);
    },
    checkPackageStatus(status) {
      return status == configs.PACKAGE_STATUS_ENUM.DA_NHAP_KHO_PHAT || status == configs.PACKAGE_STATUS_ENUM.KH_DA_NHAN;
    }
  },
  computed: {
    ...mapState({
      transCodes: state => _.sortBy(state.orderTransaction.all.data,'ID')
    }),
    ...mapState({
      orderCodes: state =>  _.sortBy(state.ordercn.all.data, "ID")
    }),
    ...mapState({
      configuration: state => state.configuration.selected
    }),
    orderEditRole() {
      return this.$store.state.authentication.role.OrderEditable;
    },
    orderCodeEditable() {
      return (this.orderStatus != configs.ORDER_STATUS_ENUM.huy && this.orderStatus != configs.ORDER_STATUS_ENUM.hoan_thanh && this.orderStatus != configs.ORDER_STATUS_ENUM.da_xuat_kho) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin);
    },
    tranCodeEditable() {
      return (this.orderStatus != configs.ORDER_STATUS_ENUM.huy && this.orderStatus != configs.ORDER_STATUS_ENUM.hoan_thanh) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment);
    },
    weightEditable() {
      return ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreVN) == configs.ROLE_TYPE.StoreVN);
    },
    adminRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
    },
    purchaseRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase;
    },
    paymentRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment;
    },
    storeVNRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreVN) == configs.ROLE_TYPE.StoreVN;
    },
    trancode_param() {
      return {
        filter: [
          {
            column: "OrderID",
            value: this.orderId,
            condition: "equal"
          },
          {
            column: "PackageType",
            value: this.orderType,
            condition: "equal"
          }
        ]
      };
    },
    ordercode_param() {
      return {
        filter: [
          {
            column: "OrderID",
            value: this.orderId,
            condition: "equal"
          }
        ]
      };
    }
  },
  mounted() {
    this.$store.dispatch("configuration/getDetail", 1);
    this.fetchData();
  }
};
</script>

<style scoped>
.trans-status {
  width: 180px;
}
.trans-weight {
  width: 60px;
}
.vertLine {
  border-right: 1px rgba(0, 0, 0, 0.12) !important;
  border-right-style: solid !important;
}
</style>
